import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // import calendar css
import '../styles/Event.css'
import { format } from 'date-fns';
import badminton from '../assets/images/badminton.jpg'
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

const EventDetails = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [date, setDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(false);
  const [eventDates,setEventDates] = useState([])
  const [loading,setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    fetch(`https://api-test.findmyfacility.com/v1/events/${id}/details/slots`)
      .then(res => {
        if (!res.ok) {
          throw new Error('Error fetching details');
        }
        return res.json();
      })
      .then(data => {
        setEventDetails(data);
      })
      .catch(error => console.error(error))
      .finally(()=>setLoading(false));
  }, [id]);

  useEffect(()=>{

    console.log("Event details use Effect call")
    if(eventDetails){
      const dates = eventDetails.eventSlots.map(slot => slot.startDate.substring(0, 10));
      setEventDates(dates)
    }


  },[eventDetails])

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');  
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  
  const isEventDate = (date) => {
    const formattedDate = formatDate(date); 
    return eventDates.includes(formattedDate);
  };
  
  const handleDateChange = (date) => {

    setDate(date);
  };

  const handleTimeSelect = (event) => {
    setSelectedTime(!selectedTime);
  };

  const handleDaySelect = (selectedDate) =>{
    setSelectedTime(false);

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');


    const slots = eventDetails.eventSlots

    const filteredTime=slots.filter((date2) => date2.startDate.includes(formattedDate) && date2.endDate.includes(formattedDate));


    const formattedTimeSlots = filteredTime.map(time => {
      const start = new Date(time.startDate);
      const end = new Date(time.endDate);
      const startTime = `${start.getUTCHours().toString().padStart(2, '0')}:${start.getUTCMinutes().toString().padStart(2, '0')}`;
      const endTime = `${end.getUTCHours().toString().padStart(2, '0')}:${end.getUTCMinutes().toString().padStart(2, '0')}`;
      //return `${startTime} - ${endTime}`;
      return {time:`${startTime} - ${endTime}`,duration : time.duration , price : time.price}
    });
    setTimeSlots(formattedTimeSlots)


  }

  console.log(eventDates, "Dates")
  return (
    loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </div>
    ) : (
      eventDetails ? (
        <>
          {/* Full-Width Header with Image */}
          <div className='image-container'>
            <img
              src={eventDetails.eventDetails.photoUrl ? eventDetails.eventDetails.photoUrl[0] : badminton}
              alt="Event"
              className="event-image"
            />
            <h1 className="event-heading">{eventDetails.eventDetails.organiserName}</h1>
          </div>
  
          <Container className="event-page" style={{ height: '100%', minHeight: '100vh', maxHeight: '100vh' }}>
            <Row className="g-4"> {/* 'g-4' adds gutter (spacing) between columns */}
              {/* About the Facility Section (Left) */}
              <Col md={8} className="about-venue d-flex flex-column justify-content-between">
                {/* First Section (Top) */}
                <div className="facility-details" style={{ textAlign: 'left', marginBottom: '10px', flexGrow: '0' }}>
                  <p>2 courts</p>
                  <p>Up to 4 people</p>
                  <p>Oasis Center, London</p>
                </div>
  
                {/* Middle Section (Center) */}
                <div style={{ textAlign: 'left', flexGrow: '0', marginBottom: '20px', marginTop: '10px' }}>
                  <h2 className="section-heading">About the facility</h2>
                  <p>{eventDetails.eventDetails.organiserDescription}</p>
                </div>
  
                {/* Last Section (Bottom) */}
                <div className="contact-details" style={{ textAlign: 'left', border: '1px solid #ccc', padding: '10px', marginTop: 'auto' }}>
                  <Row className="my-4 details-section">
                    <Col>
                      {eventDetails.eventDetails.isCoached && <p>Coached</p>}
                    </Col>
                    <Col>
                      {eventDetails.eventDetails.membershipRequired && <p>Membership Required</p>}
                    </Col>
                  </Row>
                  
                  <h3 className="section-heading">Contact Details</h3>
                  <p>{eventDetails.eventDetails.organiserName}</p>
                  <p>{eventDetails.eventDetails.organiserPhone}</p>
                  <p>{eventDetails.eventDetails.address}</p>
                </div>
              </Col>
  
              {/* Booking Section (Right) */}
              <Col md={4}>
                <Row className="book-section" style={{ backgroundColor: '#7E42F5', padding: '20px', borderRadius: '8px', color: 'white' }}>
                  <h2>Book Now</h2>
                  <Calendar
                    onChange={handleDateChange}
                    value={date}
                    onClickDay={handleDaySelect}
                    tileContent={({ date, view }) => {
                      if (view === 'month' && isEventDate(date)) {
                        return (
                          <div className="event-dot">
                            {/* This adds a small dot below the date */}
                            <span style={{
                              display: 'block',
                              marginTop: '2px',
                              width: '6px',
                              height: '6px',
                              borderRadius: '50%',
                              backgroundColor: '#7800ff',
                              margin: '0 auto'
                            }}></span>
                          </div>
                        );
                      }
                      return null;
                    }}
                    className="mb-4 calendar-custom"
                  />
  
                  <div className="time-slots">
                    {timeSlots && timeSlots.length > 0 ? (
                      timeSlots.map((time, index) => (
                        <Button
                          key={index}
                          variant={selectedTime ? 'primary' : 'outline-primary'}
                          onClick={handleTimeSelect}
                          className="time-slot mb-2"
                          style={{ backgroundColor: 'white', color: '#7E42F5', borderColor: '#7E42F5', fontSize: '12px' }}
                        >
                          <span style={{ marginRight: '10px' }}>{time.time}</span>
                          <span style={{ marginRight: '10px' }}>£{time.price}</span>
                          <span>{time.duration}</span>
                        </Button>
                      ))
                    ) : (
                      <h4>No slots for this day</h4>
                    )}
                  </div>
  
                  <Button className="mt-3" style={{ backgroundColor: 'white', color: '#7E42F5', width: '100%' }}>
                    Book now
                  </Button>
                </Row>
  
                <Row className="mt-4">
                  <Card style={{ width: '18rem', border: 'none' }}>
                    <Card.Body>
                      <Card.Title>Add your activity</Card.Title>
                      <Card.Text>
                        Do you have one or more activities to promote?<br />
                        Add yours now?
                      </Card.Text>
                      <Button
                        style={{ backgroundColor: "#7800ff" }}
                        onClick={() => window.location.href = 'https://www.findmyfacility.com/facility-signup'}
                      >
                        Add activity
                      </Button>
                    </Card.Body>
                  </Card>
  
                  <Card style={{ width: '18rem', border: 'none' }}>
                    <Card.Body>
                      <Card.Title>Claim this listing</Card.Title>
                      <Card.Text>
                        Is this your business?<br />Claim it.<br />
                        Now to update and edit all details.
                      </Card.Text>
                      <Button
                        style={{ backgroundColor: "#7800ff" }}
                        onClick={() => window.location.href = 'https://www.findmyfacility.com/claim-this-page'}
                      >
                        Claim now
                      </Button>
                    </Card.Body>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <h1>No data to show</h1>
      )
    )
  );
}  

export default EventDetails;


