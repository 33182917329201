import React from 'react'
import SearchPage from '../SearchPage'
import ToggleBar from './ToggleBar'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import '../../styles/HomePage.css'
import EventMap from "./EventMap";
import Header from "../Header";
import Events from '../Events/Events';


const Overview = ({details,updateFilters,setSportNr,setClassNr,loading,setLoading}) => {


    console.log(details,'HOME')
    return (
        <>
            <SearchPage updateFilters={updateFilters}/>
            <Container fluid style={{marginTop:"10px"}}>
                <Row>
                    {/* Sidebar on the left */}
                    <Col xl={1} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <ToggleBar details={details} updateFilters={updateFilters}/>
                    </Col>

                    <Col xl={3} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <h2>Nearby Reservations</h2>
                        <div style={{ transform: 'scale(0.85)', transformOrigin: 'top left' }}>
                            <Events details={details} updateFilters={updateFilters} cardsPerRow={1} setSportNr={setSportNr} setClassNr={setClassNr}  loading={loading} setLoading={setLoading} />
                        </div>
                    </Col>

                    <Col xl={8} style={{ maxHeight: '100vh',maxWidth:'160vh',overflowY: 'auto'}}>
                        <EventMap details={details} loading={loading} setLoading={setLoading}/>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Overview